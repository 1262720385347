import React       from "react";
import handsMobile from "../../../../assets/images/tutoring/red-shirt.jpg";
import "./Intro.scss";

const Intro = () => {
  return (
    <div className="our-tutoring">
      <div className="hands">
        <img
          className="our-tutoring-img"
          src={handsMobile}
          alt="writing hand"
        />
      </div>
      <div className="tutoring-text block">
        <div className="section-title">Tutoring programs</div>
        <div>
          Rath Tutoring tutors are available for all test prep, tutoring, and 
          academic mentorship goals for American and British education 
          systems.
        </div>
        <div>
          Rath Tutoring programs are custom tailored to each student, starting 
          with a consultation to evaluate a student's starting point, goal, 
          availability, and timeline.
        </div>
        <div>
          Test prep and single-subject tutoring programs typically meet once or 
          twice per week for a few months, with semester-long or annual programs 
          available for more comprehensive coursework.
        </div>
        <div>
          In addition to the oversight of a dedicated program director,
          students and parents receive automated reminders prior to every session, 
          and detailed tutoring notes after every session to ensure consistent 
          progress.
        </div>
        <div className="button">
          <a href="/contact">
            <div>Schedule your complimentary consultation today</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Intro;
